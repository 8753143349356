.App{
  font-family: 'Archivo', sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.card:hover {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
}

.title{
  font-family: 'Roboto Condensed', sans-serif;
}

.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}
